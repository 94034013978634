






































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsVerticalPercentageBar from '@/components/VsVerticalPercentageBar/Index.vue'
import moment from 'moment'
import html2pdf from 'html2pdf.js'
import {
    getCampaignTrendUniqueClick,
    getCampaignLinks,
    getCampaignSubscribersTotalClick,
    getCampaignSubscribersTotalOpen,
} from '@/api/consoleApi/reports'
import VsChart from '@/components/VsChart/Index.vue'
import { formatUnixDate } from '@/utils/formatter'
import VsTable from '@/components/VsTable/Index.vue'
import { UserModule } from '@/store/modules/user'
import { downloadCsv } from '@/utils/export'

@Component({
    name: 'VsSmsStatisticsFullEngagement',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsVerticalPercentageBar,
        VsChart,
        VsLoader,
        VsSectionHeader,
        VsTable,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) statistics!: any
    @Prop({ default: null, required: true }) campaign!: any
    private open = false
    private loading = false
    private clickTime: 'last24Hours' | 'last7Days' | 'last30Days' = 'last24Hours'

    private contactEvent: 'open' | 'click' = 'open'
    private contactData = []
    private totalContact = 0
    private paginationContact = {
        limit: 5,
        page: 1,
    }

    private linkData = []
    private totalLink = 0
    private paginationLink = {
        limit: 5,
        page: 1,
    }

    $refs: any

    private chartOptions: any = {
        tooltip: {
            trigger: 'axis',
            formatter: (payload: any) => {
                return `<span class="vs-h200">${payload[0].axisValue}</span><br/>${payload[0].marker} ${payload[0].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[0].value)}</span>`
            },
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: 15,
            right: 15,
            bottom: 0,
            top: 40,
            containLabel: true,
        },
        legend: {
            selectedMode: false,
            itemGap: 30,
            data: [
                {
                    name: 'Click unici',
                },
            ],
        },
        xAxis: {
            type: 'category',
            axisTick: {
                show: false,
            },
            axisLabel: {
                rotate: 25,
                margin: 15,
            },
            nameTextStyle: {
                color: '#60686A',
            },
            data: [],
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                min: 0,
                nameTextStyle: {
                    color: '#60686A',
                },
                axisLabel: {
                    formatter: (value: number) => {
                        return this.$options?.filters?.formatNumber(value)
                    },
                },
            },
        ],
        series: [
            {
                name: 'Click unici',
                data: [],
                type: 'line',
                smooth: true,
                showBackground: false,
                areaStyle: {
                    color: 'rgba(208, 52, 73, 0.3)',
                },
                itemStyle: {
                    borderWidth: 1,
                    borderColor: 'rgba(208, 52, 73)',
                    color: 'rgba(208, 52, 73)',
                },
                lineStyle: {
                    color: 'rgba(208, 52, 73)',
                },
                backgroundStyle: {
                    color: 'rgba(208, 52, 73, 0.2)',
                },
            },
        ],
    }

    get formatDateFromTimeSelected () {
        switch (this.clickTime) {
            case 'last24Hours': return 'HH:mm'
            default: return 'DD MMM YYYY'
        }
    }

    get user () {
        return UserModule.user
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    public async openModal () {
        this.open = true
        this.loading = true
        await this.getClickTrends()
        await this.getCampaignLinks()
        await this.getContactTableData()
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }

    private getMinMaxBarsHeight (height: number) {
        if (height < 0) return 0
        if (height > 100) return 100
        return height
    }

    get activeClick () {
        if (!this.statistics || !this.statistics.click.last) return false
        return moment().diff(moment.unix(this.statistics.click.last), 'days') <= 7
    }

    get deliveryHeight () {
        if (!this.statistics) return 0
        return this.statistics.sent.sent_percentage
    }

    get clickSmsHeight () {
        if (!this.statistics) return 0
        return (this.deliveryHeight / 100) * (this.statistics.delivery.delivery_percentage / 100) * 100
    }

    @Watch('clickTime', { immediate: false })
    async getClickTrends () {
        try {
            const res = await getCampaignTrendUniqueClick(this.campaign.id, { period: this.clickTime })
            this.chartOptions.xAxis.data = res.data.data.map((el: any) => formatUnixDate(el.timestamp, this.formatDateFromTimeSelected))
            this.chartOptions.series[0].data = res.data.data.map((el: any) => el.value)
        } catch (e) {
            console.log(e)
        }
    }

    @Watch('paginationLink', { immediate: false, deep: true })
    async getCampaignLinks () {
        try {
            const resp = await getCampaignLinks(this.campaign.id, this.paginationLink)
            if (resp.data.data.length === 0 && this.paginationLink.page > 1) {
                this.paginationLink.page = 1
            }
            this.linkData = resp.data.data
            this.totalLink = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.linkData = []
            this.totalLink = 0
        }
    }

    async loopCall (page: number, values: any, caller: any) {
        const resp = await caller(this.campaign.id, {
            page,
            limit: 1000,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }

    async exportLink () {
        this.loading = true
        const links: any[] = []

        await this.loopCall(1, links, getCampaignLinks)
        const csvArray: any[] = [['Link', 'Tag comportamentali', 'Click totali']]
        links.forEach(link => {
            csvArray.push([link.link, link.tag, link.total_click])
        })
        const csv = csvArray.map(row => row.join(',')).join('\n')
        downloadCsv(csv, `${this.campaign?.name}_engagement_sui_link`)
        this.loading = false
    }

    async downloadPdf () {
        this.loading = true
        this.$refs.printContent.$el.style.width = '800px'
        this.$refs.chart.chartResizeHandler()
        const opt = {
            filename: `${this.campaign?.name}_engagement.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                windowWidth: 800,
            },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
            },
        }
        setTimeout(async () => {
            await html2pdf().from(this.$refs.printContent.$el).set(opt).save()
            this.$refs.printContent.$el.style.width = '100%'
            this.$refs.chart.chartResizeHandler()
            this.loading = false
        }, 1000)
    }

    @Watch('contactEvent', { immediate: false })
    @Watch('paginationContact', { immediate: false, deep: true })
    async getContactTableData () {
        try {
            if (this.contactEvent === 'open') {
                const resp = await getCampaignSubscribersTotalOpen(this.campaign.id, this.paginationContact)
                if (resp.data.data.length === 0 && this.paginationContact.page > 1) {
                    this.paginationContact.page = 1
                }
                this.contactData = resp.data.data
                this.totalContact = resp.data.meta.pagination.total
            } else {
                const resp = await getCampaignSubscribersTotalClick(this.campaign.id, this.paginationContact)
                if (resp.data.data.length === 0 && this.paginationContact.page > 1) {
                    this.paginationContact.page = 1
                }
                this.contactData = resp.data.data
                this.totalContact = resp.data.meta.pagination.total
            }
        } catch (e) {
            console.log(e)
            this.contactData = []
            this.totalContact = 0
        }
    }

    async exportContact () {
        this.loading = true
        const contacts: any[] = []

        await this.loopCall(1, contacts, this.contactEvent === 'open' ? getCampaignSubscribersTotalOpen : getCampaignSubscribersTotalClick)
        const csvArray: any[] = [['Id contatto', 'Id lista', 'Numero di telefono', this.contactEvent === 'open' ? 'Aperture totali' : 'Click totali']]
        contacts.forEach(contact => {
            csvArray.push([contact.subscriber_id, contact.recipient_id, contact.mobile, contact.value])
        })
        const csv = csvArray.map(row => row.join(',')).join('\n')
        downloadCsv(csv, this.contactEvent === 'open' ? `${this.campaign?.name}_engagement_per_contatti_aperture` : `${this.campaign?.name}_engagement_per_contatti_click`)
        this.loading = false
    }
}
