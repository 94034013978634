


































import { ICampaign } from '@/api/consoleApi/dto/campaigns.dto'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'CampaignReportModal',
})
export default class extends Vue {
    private campaign: ICampaign | null = null
    private open = false
    public loading = false

    get reportNotAvailable () {
        return this.campaign?.sent_report === 'NotAvailable'
    }

    public openModal (campaign: ICampaign) {
        this.campaign = campaign
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    openSupport () {
        this.$root.$vsOpenUserSupport(true)
        this.closeModal()
    }
}
