export const downloadCsv = (csvString: string, name: string) => {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([csvString], { type: 'text/csv' })
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // Passing the blob downloading url
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', `${name}.csv`)
    // Performing a download with click
    a.click()
}

export const downloadHtml = (content: string, name: string) => {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([content], { type: 'text/html' })
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // Passing the blob downloading url
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', `${name}.html`)
    // Performing a download with click
    a.click()
}

export const downloadText = (content: string, name: string) => {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([content], { type: 'text/plain' })
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // Passing the blob downloading url
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', `${name}.txt`)
    // Performing a download with click
    a.click()
}
