










































import { Component, Vue, Prop } from 'vue-property-decorator'
import VsTabs, { VsTab } from '@/components/VsTabs/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownText from '@/components/VsDropdownText/Index.vue'

@Component({
    name: 'VsTabsHeading',
    components: {
        VsTabs,
        VsSectionHeader,
        VsDropdownText,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: false }) private backTo!: any
    @Prop({ default: '', required: false }) private backTip!: string
    @Prop({ default: '', required: false }) private heading!: string
    @Prop({ default: false, required: false, type: Boolean }) private disabledHeading!: boolean
    @Prop({ required: true, default: () => [] }) tabs!: VsTab[]
    @Prop({ required: true, default: '' }) activeTab!: string
    @Prop({ required: false, default: 'large' }) size!: string

    get headerSpacing () {
        return {
            'vs-mb-4': true,
            'sm:vs-mb-8': this.size !== 'small',
            'sm:vs-mb-6': this.size === 'small',
        }
    }
}
