






































import { formatNumber } from '@/utils/formatter'
import { Component, Prop, Vue } from 'vue-property-decorator'

export interface IPercentage {
    color?: string
    rateValue: number
    label?: boolean
    title: string
    value: number
}

@Component({
    name: 'VsVerticalPercentageBar',
    components: {},
})
export default class extends Vue {
    @Prop({
        default: '100%',
        required: false,
    }) height!: string

    @Prop({
        default: () => [],
        required: true,
    }) percentages!: IPercentage[]

    @Prop({
        default: '',
        required: false,
    }) label!: string

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) dark!: boolean

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) showTip!: boolean

    get tippyOptions () {
        const rows: string[] = []
        this.percentages.forEach(el => {
            rows.unshift(`<div class="vs-flex vs-justify-between" style="min-width: 200px;"><div class="vs-body-large vs-flex vs-items-center vs-gap-1"><div class="vs-flex-none" style="width:16px;height:16px;background: ${el.color};border-radius: 16px;"></div><div>${el.title}</div></div><div class="vs-h300">${formatNumber(el.value)}</div></div>`)
        })
        return {
            followCursor: true,
            content: `<div class="vs-p-4">${rows.join('')}</div>`,
            theme: 'vs',
        }
    }

    private getFlexRateValue (rate: number) {
        if (rate < 0) return 0
        if (rate > 100) return 100
        return rate
    }
}
