








































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import html2pdf from 'html2pdf.js'
import {
    getCampaignSubscribersSpamReport,
    getCampaignSubscribersBounceReport,
    getCampaignSubscribersUnsubscription,
    getCampaignTrendUnsubscription,
} from '@/api/consoleApi/reports'
import VsChart from '@/components/VsChart/Index.vue'
import { formatUnixDate, formatNumber } from '@/utils/formatter'
import VsTable from '@/components/VsTable/Index.vue'
import { UserModule } from '@/store/modules/user'
import { downloadCsv } from '@/utils/export'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'VsEmailStatisticsFullQuality',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsChart,
        VsLoader,
        VsSectionHeader,
        VsTable,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) statistics!: any
    @Prop({ default: null, required: true }) campaign!: any
    private open = false
    private openHeatmap = false
    private loading = false
    private unsubscriptionTime: 'last24Hours' | 'last7Days' | 'last30Days' = 'last24Hours'

    private unsubscriptionData = []
    private totalUnsubscription = 0
    private paginationUnsubscription = {
        limit: 5,
        page: 1,
        orderBy: 'last_timestamp',
        sortedBy: 'desc',
    }

    private spamData = []
    private totalSpam = 0
    private paginationSpam = {
        limit: 5,
        page: 1,
        orderBy: 'last_timestamp',
        sortedBy: 'desc',
    }

    private bounceData = []
    private totalBounce = 0
    private paginationBounce = {
        limit: 5,
        page: 1,
        orderBy: 'last_timestamp',
        sortedBy: 'desc',
    }

    $refs: any

    private chartOptions: any = {
        tooltip: {
            trigger: 'axis',
            formatter: (payload: any) => {
                return `<span class="vs-h200">${payload[0].axisValue}</span><br/>${payload[0].marker} ${payload[0].seriesName}:  <span class="vs-h200">${this.$options?.filters?.formatNumber(payload[0].value)}</span>`
            },
            axisPointer: {
                type: 'shadow',
            },
        },
        grid: {
            left: 15,
            right: 15,
            bottom: 0,
            top: 40,
            containLabel: true,
        },
        legend: {
            selectedMode: false,
            itemGap: 30,
            data: [
                {
                    name: 'Disiscrizioni',
                },
            ],
        },
        xAxis: {
            type: 'category',
            axisTick: {
                show: false,
            },
            axisLabel: {
                rotate: 25,
                margin: 15,
            },
            nameTextStyle: {
                color: '#60686A',
            },
            data: [],
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                min: 0,
                // max: this.yAxisRoundTo,
                // interval: this.yAxisRoundTo / this.yAxisNumberOfItem,
                nameTextStyle: {
                    color: '#60686A',
                },
                axisLabel: {
                    formatter: (value: number) => {
                        return this.$options?.filters?.formatNumber(value)
                    },
                },
            },
        ],
        series: [
            {
                name: 'Disiscrizioni',
                data: [],
                type: 'line',
                smooth: true,
                showBackground: false,
                areaStyle: {
                    color: 'rgba(208, 52, 73, 0.3)',
                },
                itemStyle: {
                    borderWidth: 1,
                    borderColor: 'rgba(208, 52, 73)',
                    color: 'rgba(208, 52, 73)',
                },
                lineStyle: {
                    color: 'rgba(208, 52, 73)',
                },
                backgroundStyle: {
                    color: 'rgba(208, 52, 73, 0.2)',
                },
            },
        ],
    }

    get formatDateFromTimeSelected () {
        switch (this.unsubscriptionTime) {
            case 'last24Hours': return 'HH:mm'
            default: return 'DD MMM YYYY'
        }
    }

    get user () {
        return UserModule.user
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    get sentThresholds () {
        return get(AppModule.consoleConf, 'sentThresholds', { unsubscription: 3, bounce: 7, spam: 0.1 })
    }

    get isUnsubscriptionUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.unsubscriptions.unsubscription_percentage < this.sentThresholds.unsubscription
    }

    get isSpamUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.spam.spam_percentage < this.sentThresholds.spam
    }

    get isBounceUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.bounce.bounce_percentage < this.sentThresholds.bounce
    }

    public async openModal () {
        this.open = true
        this.loading = true
        await this.getUnsubscriptionTrends()
        await this.getUnsubscriptionTableData()
        await this.getBounceTableData()
        await this.getSpamTableData()
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }

    @Watch('unsubscriptionTime', { immediate: false })
    async getUnsubscriptionTrends () {
        try {
            const resp = await getCampaignTrendUnsubscription(this.campaign.id, { period: this.unsubscriptionTime })
            this.chartOptions.xAxis.data = resp.data.data.map((el: any) => formatUnixDate(el.timestamp, this.formatDateFromTimeSelected))
            this.chartOptions.series[0].data = resp.data.data.map((el: any) => el.value)
        } catch (e) {
            console.log(e)
        }
    }

    async loopCall (page: number, values: any, caller: any) {
        const resp = await caller(this.campaign.id, {
            page,
            limit: 1000,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }

    async exportUnsubscription () {
        this.loading = true
        const contacts: any[] = []

        await this.loopCall(1, contacts, getCampaignSubscribersUnsubscription)
        const csvArray: any[] = [['Id contatto', 'Id lista', 'Indirizzo Email', 'Data disiscrizione']]
        contacts.forEach(contact => {
            csvArray.push([contact.subscriber_id, contact.recipient_id, contact.email, this.$options.filters?.formatUnixDate(contact.last_timestamp) || ''])
        })
        const csv = csvArray.map(row => row.join(',')).join('\n')
        downloadCsv(csv, `${this.campaign?.name}_quality_disiscritti`)
        this.loading = false
    }

    @Watch('paginationUnsubscription', { immediate: false, deep: true })
    async getUnsubscriptionTableData () {
        try {
            const resp = await getCampaignSubscribersUnsubscription(this.campaign.id, this.paginationUnsubscription)
            if (resp.data.data.length === 0 && this.paginationUnsubscription.page > 1) {
                this.paginationUnsubscription.page = 1
            }
            this.unsubscriptionData = resp.data.data
            this.totalUnsubscription = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.unsubscriptionData = []
            this.totalUnsubscription = 0
        }
    }

    async exportSpam () {
        this.loading = true
        const contacts: any[] = []

        await this.loopCall(1, contacts, getCampaignSubscribersSpamReport)
        const csvArray: any[] = [['Id contatto', 'Id lista', 'Indirizzo Email', 'Ultima segnalazione spam']]
        contacts.forEach(contact => {
            csvArray.push([contact.subscriber_id, contact.recipient_id, contact.email, this.$options.filters?.formatUnixDate(contact.last_timestamp) || ''])
        })
        const csv = csvArray.map(row => row.join(',')).join('\n')
        downloadCsv(csv, `${this.campaign?.name}_quality_segnalazioni_spam`)
        this.loading = false
    }

    @Watch('paginationSpam', { immediate: false, deep: true })
    async getSpamTableData () {
        try {
            const resp = await getCampaignSubscribersSpamReport(this.campaign.id, this.paginationSpam)
            if (resp.data.data.length === 0 && this.paginationSpam.page > 1) {
                this.paginationSpam.page = 1
            }
            this.spamData = resp.data.data
            this.totalSpam = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.spamData = []
            this.totalSpam = 0
        }
    }

    async exportBounce () {
        this.loading = true
        const contacts: any[] = []

        await this.loopCall(1, contacts, getCampaignSubscribersBounceReport)
        const csvArray: any[] = [['Id contatto', 'Id lista', 'Indirizzo Email', 'Tipo di bounce', 'Data bounce']]
        contacts.forEach(contact => {
            csvArray.push([contact.subscriber_id, contact.recipient_id, contact.email, contact.type, this.$options.filters?.formatUnixDate(contact.last_timestamp) || ''])
        })
        const csv = csvArray.map(row => row.join(',')).join('\n')
        downloadCsv(csv, `${this.campaign?.name}_quality_bounce`)
        this.loading = false
    }

    @Watch('paginationBounce', { immediate: false, deep: true })
    async getBounceTableData () {
        try {
            const resp = await getCampaignSubscribersBounceReport(this.campaign.id, this.paginationBounce)
            if (resp.data.data.length === 0 && this.paginationBounce.page > 1) {
                this.paginationBounce.page = 1
            }
            this.bounceData = resp.data.data
            this.totalBounce = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.bounceData = []
            this.totalBounce = 0
        }
    }

    async downloadPdf () {
        this.loading = true
        this.$refs.printContent.$el.style.width = '800px'
        this.$refs.chart.chartResizeHandler()
        const opt = {
            filename: `${this.campaign?.name}_quality.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                windowWidth: 800,
            },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
            },
        }
        setTimeout(async () => {
            await html2pdf().from(this.$refs.printContent.$el).set(opt).save()
            this.$refs.printContent.$el.style.width = '100%'
            this.$refs.chart.chartResizeHandler()
            this.loading = false
        }, 1000)
    }

    private formatNumber (value: number) {
        return formatNumber(value)
    }
}
